<template>

  <div style="background-color: #0f1925;" v-show="!hidden">

    <div class="trend-item p-1 px-2 league" >

      <div class="d-flex">

        <div class="trend-text1 hover-pointer mr-1 pr-2"  data-toggle="collapse" v-bind:data-target="'#collapseMarketCategory'+competition.tournament_id" aria-expanded="false" v-bind:aria-controls="'collapseMarketCategory'+competition.tournament_id">
          <span class="bold" v-text="category_name"></span> / <span v-text="competition.tournament_name"></span> <span class="numbers">({{ competition.outright_count }})</span>
        <span style="position: relative;top: 6px;">
          <arrow-right-icon style="height: 1em;"></arrow-right-icon>
        </span>
        </div>

      </div>
      

     
    </div>
    <div class="collapse" v-bind:id="'collapseMarketCategory'+ competition.tournament_id">
      <Outcome
        
        v-for="(f,index) in competition.outright_odds"
        v-bind:key="getKey(index)"
        v-bind:tournament_id="competition.tournament_id"
        v-bind:outcome="f"
        v-bind:category_name="category_name"
        v-bind:sport_id="sport_id"
        v-bind:tournament_name="competition.tournament_name">
    </Outcome>
    </div>
    

   
  </div>

</template>

<script>

import Outcome from './Outcome'

export default {
  name: "Market",
  mounted: function () {

    this.visible = this.is_visible;

  },
  methods: {
    setBusy: function () {

      this.busy = true;

    },
    unBusy: function () {

      this.busy = false;

    },
    setFixture: function (fixtures) {

      this.fixtures = fixtures;
      this.visible = true;

    },
    toggleVisibility: function () {

      this.visible = !this.visible;

    },

    loadData: function () {

      this.toggleVisibility();

    },


    sportIcon: function (sport_id) {
      return this.getSportIcon(sport_id);
    },

    getKey: function (index) {

      var prefix = "fixture-" + index;
      return Math.random().toString(10).replace('0.', 'match-id-' + prefix + '-');

    },
  },
  computed: {
    market_id: function () {
      return this.$store.state.market_id;
    },
    hour: function () {
      return this.$store.state.hour;
    },
    competition_id: function () {
      return this.$store.state.competition_id;
    },
  },
  components: {
    Outcome,
  },
  data: function () {
    return {
      has_fixture: false,
      visible: false,
      busy: false,
      hidden: false,
    }
  },
  props: {
    competition: {
      required: true,
    },
    is_visible: {
      required: false,
      default: false
    },
    is_busy: {
      required: false,
      default: false
    },
    category_name: {
      required: true,
    },
    sport_id: {
      required: true,
    },
  },
}

</script>