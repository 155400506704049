<template>
<div>

  <Market
      v-for="(l,index) in leagues"
      v-bind:key="getLeagueKey(index)"
      v-bind:competition="l"
      v-bind:is_visible="l.is_visible"
      v-bind:category_name="category_name"
      v-bind:sport_id="sport_id">
  </Market>

  <div class="timeline-wrapper" v-show="busy">

    <div class="timeline-item">

      <div class="animated-background facebook">

            <!-- lines -->

            <div class="background-masker" style="top: 0px; left: 0px; width: 60%; height: 7px; "></div>

            <div class="background-masker" style="top: 17px; left: 0px; width: 60%; height: 7px; "></div>

            <div class="background-masker" style="top: 34px; left: 0px; width: 60%; height: 7px; "></div>

            <div class="background-masker" style="top: 51px; left: 0px; width: 60%; height: 7px; "></div>

            <div class="background-masker" style="bottom: 0px; left: 0px; width: 60%; height: 5px; "></div>

            <!-- odds separator -->
            <div class="background-masker" style="top: 0; bottom: 0; left: 59%; width: 1%; "></div>

            <div class="background-masker" style="top: 0px; right: 0px; width: 40%; height: 7px; "></div>
            <div class="background-masker" style="bottom: 0px; right: 0px; width: 40%; height: 7px; "></div>

            <!-- odds separator -->
            <div class="background-masker" style="top: 0; bottom: 0; left: 79%; width: 1%; "></div>

      </div>

    </div>

  </div>

</div>

</template>

<script>

import Market from './Market'

export default {
  name: "Tournament",
  mounted: function() {

  },
  methods: {
    getKey: function(fixture,index){

      if(Array.isArray(fixture)) {

       var currentFixture = fixture[0];

      } else {

        currentFixture = fixture

      }

      var prefix = currentFixture === undefined || currentFixture.match_id == undefined ? index : currentFixture.match_id;
      return Math.random().toString(10).replace('0.', 'fixture-id-'+prefix + '-');

    },
    getLeagueKey: function(index){

      var prefix = 'market-'+index;
      return Math.random().toString(10).replace('0.', 'competition-id-'+prefix + '-');

    },
  },
  computed: {
    loadingDistance: function (){

      return 50

    },
    market_outcome: function (){
      return this.$store.state.market_outcome
    },
    games: function (){
      return this.$store.state.games
    },
    allLoaded: function() {

      return this.$store.state.games.current_page > 0 && this.$store.state.games.current_page === this.$store.state.games.last_page
    },
    busy: function() {

      return this.$store.state.busy
    },
    market_id: function () {
      return this.$store.state.market_id;
    },
    hour: function () {
      return this.$store.state.hour;
    },
    date: function (){
      return this.$store.state.upcoming_date
    }
  },
  components: {
    Market
  },
  data: function (){
    return {
      league: {},
    }
  },
  props: {
    leagues: {
      required: true,
      default: []
    },
    load: {
      required: true,
      default: false
    },
    category_name: {
      required: true,
    },
    sport_id: {
      required: true,
    }
  },
}

</script>